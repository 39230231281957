.row {
  border-left: 0.3rem solid var(--bs-hi-red-light) !important;
}
.row:hover {
  border-left: 0.3rem solid var(--bs-hi-red) !important;
}

.bellIcon {
  color: #00ad9d;
  cursor: pointer !important;
  padding-right: 6% !important;
}

.customIcon {
  cursor: pointer !important;
  padding-right: 6% !important;
}

.modal {
  max-width: 50% !important;
  text-align: left !important;
}

.highlight {
  background-color: lightgrey !important;
}

.dropdown {
  margin-top: 2%;
}

.datepickerContainer {
  display: flex;
  justify-content: center;
}

.datePicker {
  margin-top: 6% !important;
}

.customIcon {
  cursor: pointer !important;
  padding-right: 6% !important;
}

.emptyCell {
  width: 3.1rem;
}

.openedRule th {
  background-color: #f1f1f1;
  padding: 1rem 0;
}

.editIcon,
.deleteIcon,
.resolveAllIcon,
.dismissAllIcon {
  color: gray !important;
  cursor: pointer !important;
}

.editIcon:hover {
  color: black !important;
}

.deleteIcon:hover {
  color: orange !important;
}

.resolveAllIcon:hover {
  color: green !important;
}

.dismissAllIcon:hover {
  color: red !important;
}

.pauseMenu {
  width: auto !important;
}

.pausePopup::before {
  content: none !important;
}

.deleteButton {
  background-color: #d0191a !important;
  color: white !important;
}
