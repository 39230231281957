
.openedNotifications {
  background-color: #F1F1F1 !important;
}

.resolveIcon, .dismissIcon {
  cursor: pointer !important;
  color: gray !important;
}

.resolveIcon:hover {
  color: green !important;
}

.dismissIcon:hover {
  color: red !important;
}

.emptyCell {
  width: 3.1rem;
}
