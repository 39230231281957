.icon {
  display: inline-block;
}

.loading {
  color: var(--bs-gray);
}

.bordered {
  box-shadow: none !important;
  padding: 0.5em;
}

i.mail {
  color: var(--mail-color);
}
i.mail.bordered {
  background-color: var(--mail-color-light);
}

i.call {
  color: var(--call-color);
}
i.call.bordered {
  background-color: var(--call-color-light);
}

i.tasks {
  color: var(--tasks-color);
}
i.tasks.bordered {
  background-color: var(--tasks-color-light);
}

i.calendar {
  color: var(--calendar-color);
}
i.calendar.bordered {
  background-color: var(--calendar-color-light);
}

i.sms {
  color: var(--sms-color);
}
i.sms.bordered {
  background-color: var(--sms-color-light);
}

i.linkedin {
  color: var(--linkedin-color);
}

i.linkedin.bordered {
  background-color: var(--linkedin-color-light);
}
