.GoalTrackingCard:hover {
  cursor: pointer;
  box-shadow: 0 3px 5px 2px #d4d4d5, 0 0 0 1px #d4d4d5;
}

.GoalTrackingCard :global(.ui.header) {
  font-weight: normal;
  margin-bottom: 1em;
}

.GoalTrackingCard > :global(.content > .ui.grid > .row:first-child) {
  padding-bottom: 0;
}

.GoalTrackingCard > :global(.content > .ui.grid > .row:last-child) {
  padding-top: 0;
}

.GoalTrackingCard .Label :global(.ui.statistic) {
  margin: 0;
}

.GoalTrackingCard :global(.ui.statistic > .label) {
  color: var(--text-color);
  text-transform: none;
  font-weight: normal;
}

.GoalTrackingCard :global(.ui.statistic > .value) {
  font-size: 1.5rem !important;
  font-weight: bold;
  text-align: left;
}

:global(.bsCarousel) .GoalTrackingCard .Arc {
  width: 100%;
  height: 70px;
}

:global(.bsCarousel[data-columns="1"]) .GoalTrackingCard .Arc {
  height: 120px;
}

.GoalTrackingCard .Label {
  display: flex;
  column-gap: 2em;
  row-gap: 1em;
}

:global(.bsCarousel[data-columns="1"]) .GoalTrackingCard .Label {
  align-items: center;
}

:global(.bsCarousel[data-columns="2"]) .GoalTrackingCard .Label {
  align-items: flex-start;
  flex-direction: column;
}

.GoalTrackingCard .Arc .label {
  position: absolute;
  bottom: 15%;
  width: 100%;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.navigationBtns {
  cursor: "pointer";
}

.navigationBtns:hover {
  color: var(--brand-color);
}
