.container {
  margin: 0 10px;
}

.segment {
  display: flex !important;
  min-height: 80vh !important;
}

.back {
  padding: 1%;
}

.icon {
  color: var(--text-color) !important;
}

.formContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: stretch;
  padding: 4% 2% 2% 3%;
  width: 100%;
}

.form :global .disabled {
  opacity: .8!important;
}

.titleContainer {
  display: flex;
  width: 100%;
}

.title {
  font-size: 2rem;
}

.description {
  margin-bottom: 2rem;
  font-weight: bold;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 5%;
  width: 100%;
}

.checkbox {
  padding-left: 3%;
  width: 9rem;
}

.deliveryDropdown {
  min-width: 13rem !important;
}

.alertOption {
  align-items: center !important;
  display: flex !important;
  height: 38px !important;
  justify-content: center !important;
  margin-bottom: 0 !important;
  width: 100%;
}

.actionButtons {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}

.ctaSave {
  background-color: var(--brand-color) !important;
  color: white !important;
}
