.sidebar {
  background-color: white !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.title {
  text-align: left;
  padding: 1.4rem;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 5% 0;
}

.filtering {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.4rem;
}

.pill {
  color: grey !important;
  cursor: pointer !important;
  opacity: 0.5 !important;
  text-transform: uppercase !important;
}

#alert {
  background-color: #FFBABA !important;
  padding: 0.6rem 0.7rem !important;
}

#recommendation {
  background-color: #9FD9FC !important;
  margin-left: 0.5rem !important;
  padding: 0.6rem 0.7rem !important;
}

.selectedPill {
  color: #404041 !important;
  opacity: 1 !important;
}

.timeFrame {
  margin-bottom: 5%;
}

.timeFrame h4 {
  padding: 0 1.4rem !important;
}

.timeFrame p {
  color: #B8B8B8 !important;
}

.footer {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 8rem;
  padding: 1rem;
  text-transform: uppercase;
}

.footerButton {
  margin-bottom: 3% !important;
  text-transform: uppercase !important;
  width: 50%;
}

.editLink, .editLink:hover {
  color: var(--text-color);
}
