
.seeMore {
  color: var(--bs-blue) !important;
}

.seeLess {
  color: var(--bs-gray) !important;
}

.toggleExpandIcon {
  margin: 0!important;
}

.extraButtonLabel {
  opacity: .5;
}
.extraButtonLabel:hover {
  opacity: 1;
}