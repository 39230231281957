.outer {
  width: 100%;
  height: 10px;
}

.inner {
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.innerComponent {
  height: 100%;
  float: left;
}

.innerComponent:not(:first-child) {
  box-shadow: 1px 0 0 0 white inset;
}

.benchmarkLine {
  position: absolute;
  top: 30px;
  height: calc(100% - 30px);
  border-left: 2px dashed;
  padding-left: 10px;
}

.benchmarkLineFull {
  position: absolute;
  top: 0px;
  height: calc(100%);
  border-left: 2px dashed;
  padding-left: 10px;
}

.benchmarkLabel {
  position: relative;
  top: -20px;
  left: -100%;
  font-weight: bold;
  white-space: nowrap;
}

.veryBad .inner {
  background-color: var(--critical-color);
}

.bad .inner {
  background-color: var(--critical-color-light);
}

.neutral .inner {
  background-color: var(--bs-gray);
}

.good .inner {
  background-color: var(--healthy-color-light);
}

.veryGood .inner {
  background-color: var(--healthy-color);
}

.averageIndicator {
  width: 1px;
  height: 100%;
  background-color: black;
}

.bold {
  font-weight: bold;
}

.popup:global(.ui.grid) {
  padding: 1em 0;
}

.popup:global(.ui.grid > .row) {
  padding-top: 0;
  padding-bottom: 0;
}

.popup:global(.ui.grid > .row).extraSpace {
  padding-top: 0.5em;
  padding-bottom: 0;
}

.mailText {
  color: var(--mail-color);
}

.mailBg {
  background-color: var(--mail-color);
}

.callText {
  color: var(--call-color);
}

.callBg {
  background-color: var(--call-color);
}

.meetingText {
  color: var(--calendar-color);
}

.meetingBg {
  background-color: var(--calendar-color);
}

.tasksText {
  color: var(--tasks-color);
}

.tasksBg {
  background-color: var(--tasks-color);
}
