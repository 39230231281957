.activityPanel {
  cursor: pointer;
  overflow-x: auto;
}

.description {
  white-space: pre-line;
}

.tags {
  display: flex;
  line-height: 2.25em;
}

.tag {
  color: var(--brand-color) !important;
  background-color: var(--brand-color-light) !important;
}

.classification {
  color: var(--bs-green) !important;
  background-color: var(--bs-green-light) !important;
}

.addTag {
  margin: 0 !important;
}

.date {
  line-height: 2em;
}

.addTagLabel {
  opacity: 0.5;
}
.addTagLabel:hover {
  opacity: 1;
}

.loadingIcon {
  margin-left: 0.5rem !important;
}

.activityDetailRow:hover {
  background-color: var(--bs-gray-light);
}

.newTagContainer {
  min-width: 33%;
  display: "inline-block";
}
