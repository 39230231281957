.userDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}

.userDetailsItem:not(:last-child) {
  min-width: 45px;
  margin-right: 8px;
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.profilePhoto {
  width: 45px;
  height: 45px;
}

.userName {
  font-size: 16px;
}

.userEmail {
  font-size: 14px;
}
