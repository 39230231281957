.container {
  margin-right: 10px;
  overflow: hidden;
}

.pane {
  border: none !important;
  max-height: 360px;
  overflow-x: visible;
  overflow-y: auto;
}

.searchBar {
  float: right;
  margin-right: 1em;
  margin-top: 0.1em;
  margin-right: 10px;
}

.filterIcon {
  float: right;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.timelineSection {
  margin-bottom: 20px !important;
}
