.ruleRow .editCell * {
  visibility: hidden;
}

.ruleRow:hover .editCell * {
  visibility: visible;
}

.editButtons {
  justify-content: flex-end;
}

.ruleRow td{
  overflow: visible !important;
}

.ruleRow:hover{
  background-color: var(--bs-gray-light)
}

.ruleRow.editingRow{
  background-color: unset !important;
}