.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.container .inputElement{
    margin-left: 16px;
    flex-grow: 1;
}

.hourRangeContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hourRangeDivider{
    margin: 0 8px;
}