.tableContainer{
  margin-top: 1rem;
  max-height: 50vh;
  overflow-y: scroll;
}

.firstCell{
  padding-left: 11px !important;
}

.row:not(.selectedRow):hover {
  background-color: var(--bs-gray-light);
}

.selectedRow{
  background-color: var(--brand-color-light);
  color: var(--brand-color);
}
