.activity {
  display: flex;
  padding: 8px 0 12px 0px;
  overflow: hidden;
}

.activityDate {
  position: absolute;
  width: 60px;
  left: 0px;
  color: var(--text-color-light);
  align-self: center;
}

.activityDate::before {
  content: '';
  position: absolute;
  left: 62px;
  top: .5em;
  border: 4px solid #99AABB;
  border-radius: 4px;
}

.activityContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border: 1px solid #777777;
  overflow: hidden;
  margin: 0 0 0 80px!important;
  padding: 0px !important;
}

.activityContainer:hover {
  cursor: pointer;
  background-color: var(--bs-gray-light);
}

.activityIcon {
  z-index: 1;
}

.activityIcon .icon {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}

.activityContent {
  padding: 10px;
  overflow: hidden;
}

.groupedActivityTwo {
  box-shadow:
    /* The top layer shadow */
    0 1px 1px rgba(0,0,0,0.15),
    /* The second layer */
    0 10px 0 -5px #fff,
    /* The second layer shadow */
    0 10px 1px -4px rgba(0,0,0,0.15)!important;
}

.groupedActivityMore {
  box-shadow:
    /* The top layer shadow */
    0 1px 1px rgba(0,0,0,0.15),
    /* The second layer */
    0 10px 0 -5px #fff,
    /* The second layer shadow */
    0 10px 1px -4px rgba(0,0,0,0.15),
    /* The third layer */
    0 20px 0 -10px #fff,
    /* The third layer shadow */
    0 20px 1px -9px rgba(0,0,0,0.15)!important;
}

.groupCountDesc {
  color: var(--bs-blue);
}

.subject {
  font-weight: bold;
  font-size: 1.2em;
}

.meta {
  font-size: .8em;
}

.body {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  color: #777777;
}

.selected .activityContainer {
  background-color: rgba(0,0,0,0.1);
}

.mail .activityContainer {
  border-color: var(--mail-color-light);
}
.call .activityContainer {
  border-color: var(--call-color-light);
}
.tasks .activityContainer {
  border-color: var(--tasks-color-light);
}
.calendar .activityContainer {
  border-color: var(--calendar-color-light);
}
