.page {
  margin: auto;
}

.pushable {
  background-color: var(--main-bg-color) !important;
  min-height: 100vh;
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  :global(.ui.container).mainContainer {
    margin: 0 !important;
  }
}
