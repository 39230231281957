
.chartContainer{
  width: 100%;
}

.chartTitle {
  display: flex;
  justify-content: center;
  padding-bottom: 2%;
}

.chartTitle a {
  cursor: pointer !important;
}

.chartTitle p {
  font-weight: bold;
}

.chartActionsContainer{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
}

.actionIcon{
  color: var(--brand-color);
}

.actionIcon:hover{
  background-color: red;
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}