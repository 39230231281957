.dashboard {
  padding: 0 10px;
}

.activityHeader {
  display: flex;
  flex-direction: row;
}

.activityHeader div {
  padding-left: 5px;
}

.activityHeader div.selectedDateRange {
  font-weight: bold;
  text-decoration: underline;
}
