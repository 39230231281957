:root {
  --main-bg-color: #f1f1f1;
  --brand-color: #dd3e94;
  --brand-color-light: #fae2ef;
  --brand-color-transparent: rgb(221, 62, 148, 0.95);
  --bs-blue: #2185d0;
  --bs-blue-light: #deedf8;
  --bs-gray: #a5a5a5;
  --bs-gray-light: #f1f1f1;
  --bs-green: #44af69;
  --bs-green-light: #e3f3e9;
  --bs-pink: #f59ca9;
  --bs-pink-light: #fef0f2;
  --bs-purple: #8c4b9c;
  --bs-purple-light: #eee2f1;
  --bs-teal: #00b5ad;
  --bs-teal-light: #e3f3e9;
  --bs-orange: #d05e21;
  --bs-brown: #af4d44;
  --bs-lime: #a9f59c;
  --bs-hi-red: #e74939;
  --bs-hi-red-light: #ffbaba;
  --bs-hi-blue: #1f8ee9;
  --bs-hi-blue-light: #9ed9fb;
  --bs-status-green: #21ba45;
  --bs-status-green-transparent: rgba(33, 186, 69, 0.08);
  --bs-status-red: #da2928;
  --bs-status-red-transparent: rgba(218, 41, 40, 0.08);
  --bs-status-orange: #f2701d;
  --bs-status-orange-transparent: rgba(242, 112, 29, 0.08);
  --alpha: 0.1;
  --bs-graph-blue: rgb(74, 123, 214);
  --bs-graph-blue-transparent: rgba(74, 123, 214, var(--alpha));
  --bs-graph-lightblue: rgb(96, 180, 217);
  --bs-graph-lightblue-transparent: rgba(96, 180, 217, var(--alpha));
  --bs-graph-green: rgb(108, 205, 100);
  --bs-graph-green-transparent: rgba(108, 205, 100, var(--alpha));
  --bs-graph-orange: rgb(235, 128, 76);
  --bs-graph-orange-transparent: rgba(235, 128, 76, var(--alpha));
  --bs-graph-red: rgb(220, 81, 75);
  --bs-graph-red-transparent: rgba(220, 81, 75, var(--alpha));

  --text-color-dark: rgba(0, 0, 0, 0.77);
  --text-color: #777777;
  --text-color-light: #a5a5a5;
  --mail-color: var(--bs-blue);
  --mail-color-light: var(--bs-blue-light);
  --call-color: var(--bs-teal);
  --call-color-light: var(--bs-teal-light);
  --calendar-color: var(--bs-pink);
  --calendar-color-light: var(--bs-pink-light);
  --tasks-color: var(--bs-green);
  --tasks-color-light: var(--bs-green-light);
  --sms-color: var(--bs-purple);
  --sms-color-light: var(--bs-purple-light);
  --linkedin-color: rgb(10 102 194);
  --linkedin-color-light: rgb(231 238 245);

  --critical-color-light: #ff6252;
  --healthy-color-light: #65ee74;

  --critical-color: var(--bs-status-red);
  --warning-color: var(--bs-status-orange);
  --healthy-color: var(--bs-status-green);
  --critical-color-transparent: var(--bs-status-red-transparent);
  --warning-color-transparent: var(--bs-status-orange-transparent);
  --healthy-color-transparent: var(--bs-status-green-transparent);
}

body {
  color: var(--text-color);
}

.ui.secondary.pointing.menu .active.item {
  border-color: var(--brand-color) !important;
  color: var(--brand-color) !important;
}

.ui.primary.button.brand,
.ui.primary.buttons.brand .button,
.field.brand .ui.primary.button {
  background-color: var(--brand-color) !important;
}

.ui.active.button.brand {
  color: var(--brand-color) !important;
  background-color: var(--brand-color-light) !important;
}

.ui.label > .icon.clickable {
  cursor: pointer;
  opacity: 0.5;
  transition: background 0.1s ease;
  margin-right: 0;
  margin-left: 0.5em;
}
.ui.label > .icon.clickable:hover {
  opacity: 1;
}

.ui.dropdown:not(.button) > .text.default {
  color: black;
}

.ui.menu .dropdown {
  display: flex;
  align-items: center;
  border: none;
}

.ui.menu .dropdown::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background: rgba(34, 36, 38, 0.1);
}

.ui.menu .search.dropdown {
  min-width: 16em;
}

.ui.menu .search.dropdown > i.dropdown.icon {
  top: 2.15em;
  right: 2em;
}

.ui.segment {
  box-shadow: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

div#root {
  height: 100%;
  min-width: 1100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.link {
  color: #4183c4;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
div.link:hover {
  color: #1e70bf;
}

div.link.brand {
  color: var(--text-color);
}
div.link.brand:hover {
  color: var(--brand-color);
}

a.brand {
  color: var(--text-color-dark);
}
a.brand:hover {
  color: var(--brand-color);
}

.clickable:hover {
  cursor: pointer;
}
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: rgb(253, 253, 253);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-weight: bold !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: rgba(221, 62, 148, 0.75);
  color: white;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  color: #a5a5a5;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75em;
  text-decoration: none !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #070707 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 1em 0.75em !important;
  background: none;
  border-radius: 6px !important ;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgba(221, 62, 148, 0.75) !important;
  color: white;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: rgba(221, 62, 148, 0.75) !important;
  color: white;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: rgba(221, 62, 148, 0.75) !important;
}
.react-calendar__tile--active {
  background: #e2b7e4 !important;
  color: rgba(221, 62, 148, 0.75) !important;
  border-radius: 6px !important ;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.ui.table thead th {
  background-color: #e2e2e2 !important;
  font-weight: 500 !important;
}

.bs .ui.popup.inverted {
  background-color: #5a5b5d;
  color: #ffffff;
}

.bs .ui.popup.inverted:before {
  background-color: #5a5b5d !important;
}

.ui.grid > .row > .column.hidden {
  display: none !important;
}
