.toolkitContainer {
  text-align: right;
  align-content: flex-start;
}

.footerActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.link.brand:hover {
  color: var(--brand-color);
  cursor: default !important;
}

div.link.brand {
  cursor: default !important;
}

.ui.table thead th {
  font-weight: 300 !important;
}

.stageNameData {
  color: #a5a5a5;
}
.displayedData {
  color: #888787;
  text-align: center;
  align-items: center;
}
