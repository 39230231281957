.searchInput {
  min-width: 309px;
}

.calendarDropdown {
  display: flex;
  flex-direction: row;
}

.presetDates {
  margin: 0 0 0 15px;
}

.tags {
  line-height: 2.25em;
}

:global(.ui.header).tagHeader {
  margin: 0em;
  margin-top: .4em;
}

:global(.ui.header>.icon) {
  font-size: revert;
  vertical-align: revert;
}

.activeLabel {
  color: var(--brand-color)!important;
  background-color: var(--brand-color-light)!important;
}

.emailPredictionActiveLabel{
  color: var(--bs-green) !important;
  background-color: var(--bs-green-light) !important;
}

.loading {
  min-height: 100px;
}

.inactiveIcon {
  background-color: var(--bs-gray-light)!important;
}
