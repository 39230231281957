.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.nameContainer{
  flex: 1;
}

.labelContainer{
  display: flex;
  cursor: pointer;
}

.labelContainer > *{
  margin-right: 4px;
}

.title {
  font-weight: bold;
}

.info {
  flex:1;
  text-align:center;
}

.actionButton {
  margin-left: 5px !important;
}

.iconContainer {
  flex: 1;
  text-align: right !important;
}

.icon{
  transition: transform .2s ease,opacity .2s ease,-webkit-transform .2s ease;
}

.rotate {
  transform: rotate(90deg);
}

.editIcon{
  color: var(--bs-blue);
}

.labelContainer .editCell * {
  visibility: hidden;
}

.labelContainer:hover .editCell:not(.editing) * {
  visibility: visible;
}

.active *{
  visibility: visible !important;
}

.confirmDeletionContent{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.saveBtn{
  margin-left: 4px !important;
}