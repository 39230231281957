.tooltip {
  background-color: #5a5b5d;
  color: #ffffff;
  max-height: 600px;
  max-width: unset !important;
  width: 350px;
  overflow-y: auto;
}

.tooltip.ideal {
  position: absolute;
  padding: 10px;
}

.tooltip h1 {
  font-size: 1.4rem;
}

.tooltip h2 {
  font-size: 1rem;
  margin: 0;
}

.tooltip table {
  width: 100%;
}

.tooltip table td:last-child {
  text-align: center;
}

.tooltip a {
  color: #56abff;
}

.tooltip a:hover {
  text-decoration: underline;
}
