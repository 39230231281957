.menu {
  align-items: center;
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
}

.searchInput {
  min-width: 309px;
}

.leftMenu {
  align-items: center;
  display: flex;
}

.newAlertButton {
  align-items: center !important;
  background-color: var(--brand-color) !important;
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  margin-right: 0.5rem !important;
}

.filtering {
  display: flex;
  justify-content: flex-end;
}

.pill {
  color: grey !important;
  cursor: pointer !important;
  height: 100%;
  opacity: 0.5 !important;
  text-transform: uppercase !important;
}

#alert {
  background-color: #FFBABA !important;
  margin-left: 0.5rem !important;
  padding: 0.6rem 0.7rem !important;
}

#recommendation {
  background-color: #9FD9FC !important;
  margin-left: 0.5rem !important;
  padding: 0.6rem 0.7rem !important;
}

.selectedPill {
  color: #404041 !important;
  opacity: 1 !important;
}
