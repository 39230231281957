.activityGroup {
  position: relative;
}
.activityGroup::before {
  content: '';
  position: absolute;
  top: 0;
  left: 65px;
  height: 100%;
  width: 2px;
  background: var(--bs-gray-light);
  z-index: 0;
}

.activityGroup.noLine::before {
  background: none;
}

.dateHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  margin-left: 0px;
  color: var(--text-color);
  text-transform: uppercase;
  background-color: #FFFFFF;
}

.expandToggle {
  margin-left: 10px;
  cursor: pointer;
}

.activitySection {
  position:relative;
  z-index: 1;
}

.summaryRow {
  display: flex;
  flex-direction: row;
  padding: 10px 0 10px 80px;
  justify-content: flex-start;
  align-content: center;
  vertical-align: center;
}

.summaryRow::before {
  content: '';
  position: absolute;
  left: 62px;
  top: 2.15em;
  border: 4px solid #99AABB;
  border-radius: 4px;
}

.summaryRow:hover {
  cursor: pointer;
}

.summaryLabel {
  align-self: center;
  margin: 0 10px;
  font-weight: bold;
  font-size: 1.2em;
}

.activitySummary {
  align-self: center;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0 15px 0 0;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

/* .activitySummary {
  border: 1px solid var(--bs-gray-light);
} */
.activitySummary .icon {
  padding: 10px 5px;
  margin: 0;
}
.activitySummary .count {
  padding: 10px;
  margin: auto;
}
/* .activitySummary.mail {
  border-color: var(--mail-color-light);
}
.activitySummary.call {
  border-color: var(--call-color-light);
}
.activitySummary.tasks {
  border-color: var(--tasks-color-light);
}
.activitySummary.calendar {
  border-color: var(--calendar-color-light);
} */

.activitySection .segment:hover{
  background-color: var(--bs-gray-light);
}

.activitySummary .count.mail {
  color: var(--mail-color);
}
.activitySummary .count.call {
  color: var(--call-color);
}
.activitySummary .count.tasks {
  color: var(--tasks-color);
}
.activitySummary .count.calendar {
  color: var(--calendar-color);
}

.noActivity {
  min-height: 3em;
  padding: 1em;
  position: relative;
  left: 44px;
  color: var(--bs-gray);
}

.summaryRowSegment{
  padding: 0px !important;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.summaryRowSegment:hover{
  background-color: var(--bs-gray-light);
}

.icon{
  transition: transform .2s ease,opacity .2s ease,-webkit-transform .2s ease;
}

.rotate {
  transform: rotate(90deg);
}
