.mapRow {
  margin: 5px 0;
}

.saveBtnContainer {
  margin-top:8px;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}

.saveBtn{
  background-color: var(--brand-color) !important;
  color: white !important;
}