.tableContainer{
  margin-top: 1rem;
  max-height: 50vh;
  overflow-y: scroll;
}

.modalActionsContainer{
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.modalActionsContainer > *{
  margin: 0 0.5rem;
}

.userDetailsContainer{
  display: flex;
}