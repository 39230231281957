.activityTotals {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.activityTotals div,
.activityStats div {
  margin-right: 8px;
}

.activityStats {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
