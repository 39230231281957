.settingContainer{
  margin-bottom: 8px;
}

.settingsContainer{
  position: relative;
  width: 100%;
}

.settingLabel{
  margin-left: 12px !important;
}

.smallInput{
  width: 70px;
}