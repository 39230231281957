.searchBar {
  display: flex;
  justify-content: flex-end;
}

.accountName {
  overflow: hidden;
}

.status {
  font-weight: bold;
  white-space: nowrap;
}

.unhealthy.status,
.lost.status,
.critical.status {
  color: var(--bs-status-red);
  background-color: var(--bs-status-red-transparent);
}

.healthy.status,
.won.status {
  color: var(--bs-status-green);
  background-color: var(--bs-status-green-transparent);
}

.needsAttn.status,
.warning.status {
  color: var(--bs-status-orange);
  background-color: var(--bs-status-orange-transparent);
}

.inactive.status,
.preOpportunity.status {
  color: var(--bs-gray);
  background-color: var(--bs-gray-light);
}

.listFooter {
  margin: auto;
}

.popupReasons ul {
  list-style: none;
}

.popupReasons ul li {
  margin: 0 0 5px -30px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--bs-gray-light);
}
.popupReasons ul li:last-child {
  border-bottom: none;
}
