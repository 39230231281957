.user {
  padding-bottom: 20px;
}

.userHeader {
  display: flex;
  margin-bottom: 20px;
}

.hidden {
  display: none;
}
