.tags {
  padding: 0 10px;
}

.tag {
  color: var(--brand-color) !important;
  background-color: var(--brand-color-light) !important;
}

.tagRuleSection {
  margin: 10px 0;
}
