.userManage {
  margin-left: 20px;
  padding-bottom: 1rem;
}
.addUserBtnContainer {
 display: flex;
 justify-content: flex-end;
}

.buttonCell button:first-child{
  margin-right: 1.5rem;
}

.headerContainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-direction: row;
}

.centered{
  text-align: center;
  color: #333
}