.container {
  padding: 2em;
  display: flex;
  flex-direction: column;
  border-radius: 0.285rem;
}

.valueContainer {
  font-size: 1.8rem;
  font-weight: 300;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  height: 35%;
}

.icon {
  margin-right: 4px;
  max-width: 2rem;
  max-height: 2rem;
}

i.icon {
  font-size: 21px;
  transform: translateY(-2px);
}

.labelContainer {
  text-align: center;
  color: #4b4b4b;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
}
