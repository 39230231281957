.activityTimeline {
  position: relative;
  padding: 0;
  overflow: hidden;
}

.loadingActivity {
  height: 200px;
}

.textHeader {
  font-weight: bold;
  margin-left: 75px;
}

.loadingMessage {
  color: var(--text-color);
}

.loadMore {
  margin-left: 83px !important;
}
