.recommendation {
  padding-bottom: 25px;
}

.segment {
  padding: 10px 25px 25px !important;
}

.header {
  background: linear-gradient(white 56px, white 56px, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px 0 20px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.header h1 {
  color: var(--text-color-dark);
  font-weight: 300;
  margin: 0 0 0 10px;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header div {
  display: flex;
  align-items: center;
}

.img {
  margin: 0 auto;
  max-width: 800px;
}

.resolveButton {
  margin: 0 0 0 10px !important;
}

.feedbackFormHeader {
  background-color: var(--brand-color) !important;
  color: white !important;
}
