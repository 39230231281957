.table {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  flex-grow: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}

.table .loader {
  display: unset !important;
  position: unset !important;
  top: unset !important;
  left: unset !important;
  margin: unset !important;
  text-align: unset !important;
  z-index: unset !important;
  transform: unset !important;
}

.table .loader:before {
  border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
}

.table .loader:after {
  border-color: #767676 transparent transparent !important;
}

.column :global(i.icon) {
  margin-left: 5px;
}

.column.names {
  min-width: 50px;
}

.column.charts {
  flex-grow: 2;
  position: relative;
  align-self: flex-end;
}

.column.values {
  min-width: 62px;
}

.sortable .column header:not(.disabled) {
  cursor: pointer;
}

.column header {
  height: 2.5rem;
  color: var(--brand-color);
  font-weight: bold;
}

.column header::selection {
  background: transparent;
  color: unset;
}

.column ol {
  padding: 0;
  margin: 0;
}

.column ol li {
  display: flex;
  align-items: center;
}

.table.regular .column ol li {
  height: 1.5rem;
  font-size: 0.9rem;
}

.table.big .column ol li {
  height: 2.5rem;
}

.column a {
  color: var(--text-color);
}

.column a:hover {
  color: var(--brand-color);
}
