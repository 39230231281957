.header div:first-child {
  margin-top: 0 !important;
}

.header :global(.ui.text.menu .right.menu > *) {
  margin: 0 1em;
}

.header :global(.ui.text.menu) {
  margin-top: 0.5em;
}

.header :global(.ui.text.menu .item.dropdown) {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  padding: 0.5em 1em;
  height: 100%;
}

.header :global(.ui.text.menu .item.dropdown):hover {
  border: 1px solid rgba(34, 36, 38, 0.35);
  background-color: rgba(34, 36, 38, 0.2);
}

/* Dropdown's direction="right" doesn't work so we have to do this */
:global(.ui.dropdown).calendarDropdown :global(.menu) {
  left: auto;
  right: 0;
}

.calendarDropdownContent {
  display: flex;
  flex-direction: row;
}

.presetDates {
  margin: 0 0 0 15px;
}
