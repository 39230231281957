.dashboard {
  padding-bottom: 25px;
}

.segment {
  padding: 25px !important;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header h1 {
  color: var(--text-color-dark);
  font-weight: 300;
  margin: 0;
}

.dashboard {
  margin: 0 10px 0 0;
}

.tags {
  margin-top: 2px;
  position: absolute;
  right: 25px;
}

.inbox > div:first-of-type {
  width: max-content;
}

.activeLabel {
  color: var(--brand-color) !important;
  background-color: var(--brand-color-light) !important;
}
