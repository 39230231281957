.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.contactHeader {
  margin: 0 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.activities {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.activity {
  margin-right: 0.5rem;
}
.activity.mail {
  color: var(--mail-color);
}
.activity.call {
  color: var(--call-color);
}
.activity.tasks {
  color: var(--tasks-color);
}
.activity.calendar {
  color: var(--calendar-color);
}

.stat {
  margin: 0 5px 0 10px;
  text-align: center;
}

.stat .label {
  font-weight: bold;
  margin-bottom: 10px;
}

.syncSection {
  flex-grow: 2;
  text-align: right;
  color: var(--text-color-light);
}
