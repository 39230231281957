/* TODO: maybe use for animation height later */
.container {
  display: flex !important;
  justify-content: center;
  padding: 0 !important;
}

.table {
  border: none !important;
  border-collapse: collapse !important;
  margin: 1rem !important;
  width: 100% !important;
}
