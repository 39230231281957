.legend {
  margin: 10px 0;
}

.legend,
.legend .item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.legend .item .icon {
  width: 20px;
  height: 10px;
  border-radius: 5px;
}
