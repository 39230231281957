.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.chartContainer {
  width: 100%;
}

.chartTitle {
  display: flex;
  justify-content: left;
  padding-bottom: 2%;
  font-weight: bold;
}

.chartSubTitle {
  display: flex;
  justify-content: left;
  padding-bottom: 1rem;
}

.chartTitle a {
  cursor: pointer !important;
}

:global(.ui.dropdown .menu > .item).presetButtons {
  padding: 1rem 1.5rem !important;
}
.presetButtons:global(.active.item) {
  color: var(--brand-color) !important;
}

.hidden {
  visibility: hidden;
}
