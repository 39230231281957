.App {
  min-height: 100%;
  background-color: var(--main-bg-color, whitesmoke);
  padding-top: 1em;
}

.loadingContainer {
  padding: 20px;
}

.toastContainer {
  border: 1px solid var(--brand-color-light);
  border-radius: 5px;
  background-color: var(--brand-color-transparent);
}

.toastContainer button {
  color: var(--brand-color-light);
}

.toastBody {
  color: var(--brand-color-light);
}

.toastProgress {
  background: var(--brand-color-light);
}
