.modalChartHeader {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalChartHeader .legendBox {
  display: flex;
}

.modalChartHeader .buttons {
  margin-right: 10px;
}

.modalChartHeader .datePicker {
  display: flex;
  align-items: center;
}

.statsCard {
  height: 100%;
}

.statsCard:hover {
  cursor: pointer;
  border: 1px solid var(--brand-color);
}

.statsCard .header {
  margin: 0 0 1.5em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statsCard .header .icon {
  font-size: 2.5em;
  margin: 0 0.2em 0 0;
}

.goalSubHeader {
  display: flex;
}
