.container {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  flex: 1;
  padding: 0 0px;
}

.menu {
  display: flex !important;
  height: 100%;
  background-color: white !important;
  border: none !important;
}

.appLogo {
  height: 40px;
  margin: auto;
}

.appLogogram {
  height: 40px;
  margin: auto;
}

.container.expanded :global .item .icon {
  float: left !important;
  padding-right: 2em;
}

.menu :global .item.active .icon {
  color: var(--brand-color) !important;
}

.menuItem {
  display: flex !important;
  align-items: center;
}

.menuItem .min {
  margin: 0 !important;
}

.expander {
  margin-top: auto;
}

.appMenuminimizeContainer {
  width: 26px;
  height: 100%;
}

.appMenuminimizeContainer > div {
  visibility: hidden;
}

.minimizeButton:hover {
  background-color: var(--brand-color) !important;
}

.minimizeButton:hover .minimizeIcon {
  color: white !important;
}

.minimizeButton {
  position: fixed;
  top: 24px;
  border-radius: 50%;
  background-color: white;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transform: translateX(-50%);
  box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08),
    0 2px 4px 1px rgba(9, 30, 66, 0.08);
}

.appMenuminimizeContainer:hover > div {
  visibility: visible;
}

.minimizeIcon {
  color: var(--brand-color);
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
}

.appMenuminimizeContainerMinizied .minimizeButton {
  visibility: visible !important;
}

.container:hover .appMenuminimizeContainer > div {
  visibility: visible !important;
}
