.activeOpportunityTag {
  background-color: var(--bs-blue-light) !important;
  color: var(--bs-blue) !important;
}

.inactiveOpportunityTag {
  background-color: var(--bs-gray-light) !important;
  color: var(--bs-gray) !important;
}

.actionIcon {
  margin: 0 0 0 0.5rem !important;
}
