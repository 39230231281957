.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.userInfo {
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 10px;
  text-align: left;
}

.userPhoto {
  width: 45px;
  height: 45px;
}

.stat {
  text-align: center;
  min-height: 80px;
}

.stat.profile {
  padding-left: 20px;
}

.stat .activities {
  max-width: 200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.stat .label {
  font-weight: bold;
  color: var(--text-color-dark);
  margin-bottom: 10px;
}
.stat .value {
  font-size: 1.8em;
}

.stat .hidden {
  visibility: hidden;
}

.stat:hover .hidden {
  cursor: pointer;
  visibility: visible;
}

.statLink {
  color: var(--bs-text-color);
}

.statLink > div:hover {
  color: var(--bs-text-color);
}

.stat .tag {
  font-size: 1em;
}

.stat:hover .tag .dashTag {
  color: var(--brand-color) !important;
  background-color: var(--brand-color-light) !important;
}

.stat .comparison {
  font-size: 1em;
}

.ManagerList {
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;
}

.ManagerList:global(.ui.table) thead th {
  background-color: transparent !important;
  border-left: none !important;
  white-space: normal !important;
  border-bottom: none;
}

.ManagerList td {
  background-color: #ffffff !important;
}

.ManagerList .subheader {
  font-weight: normal;
  font-size: 0.8em;
}

.ManagerList .numberMetric {
  text-align: center !important;
}

.popupContent :global(.row) {
  align-items: center !important;
}

.popupContent :global(.row) .header {
  font-weight: bold;
}

.popupContent :global(.row .column) {
  display: flex !important;
  align-items: center;
}
