.badge {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 1.4em;
}

.menuCompact {
  border-radius: 50% !important;
  border: none !important;
  box-shadow: none !important;
  display: flex !important;
  height: 3rem !important;
  justify-content: center !important;
  width: 3rem !important;
}

.activeBadge {
  background-color: #bababa !important;
}

.inactiveBadge {
  background-color: #e0e0e0 !important;
}

.icon {
  margin: 0 !important;
}

.label {
  left: 120% !important;
}
