.dropdownContainer{
  display: flex;
  justify-content: flex-start;
  flex-direction:row;
  align-items: center;
}

.dropdownContainer>* {
  margin-right: 4px;
}

.icon{
  height: auto !important;
}

.icon:hover{
  color: var(--brand-color);
}
