.header {
  margin: 0;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
}

.header :global(.ui.header) {
  margin: 0;
}
