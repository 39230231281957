.recommendationRow {
  border-top: 1px solid rgba(34, 36, 38, 0.1);
}

.recommendationRow:last-child {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.recommendationRow:hover {
  cursor: pointer;
  background-color: var(--bs-gray-light);
}

.content {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  height: 50px;
  padding: 0 10px;
  width: 100% !important;
  border-left: 5px var(--bs-hi-blue-light) solid;
}

.linkRow {
  align-items: center;
  flex-grow: 1;
  display: flex !important;
  height: 50px;
  flex-direction: row;
  color: var(--text-color-dark);
  overflow: hidden;
  position: relative;
}

.linkRow:hover {
  color: var(--text-color-dark);
}

.title {
  width: 350px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 25px;
  font-weight: 700;
  color: var(--text-color);
}

.body {
  position: absolute;
  left: 360px;
  max-width: calc(100% - 570px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color-light);
}

.tags {
  position: absolute;
  left: 360px;
  max-width: calc(100% - 570px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.new .title {
  color: var(--text-color-dark);
}

.date {
  min-width: 205px;
  padding: 0 25px;
  margin-left: auto;
  z-index: 1;
  background-color: white;
}

.recommendationRow:hover .date {
  background-color: var(--bs-gray-light);
}

.button {
  padding: 5px !important;
  text-align: center;
}
