.icon{
  align-self: center;
  margin-left: -38px !important;
  line-height: 1;
  z-index: 100;
}

.container{
  position: relative;
  display: flex;
  flex-direction: row;
}

.container :global(.dropdown) input:global(.search){
  margin-top: 4px !important;
}

.bsDropdownContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: text;
}

.subContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchInput input{
  border: unset !important;
  height: 100%;
}

.close{
  padding: 13px 16px;
  display:flex;
  justify-content: space-around;
}

.closeLabel{
  font-size: 14px;
  color: black;
  margin-right: 8px;
}

.line{
  position: relative;
}

.line::before{
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background: rgba(34,36,38,.1);
}

:global(i.icon).caretDownIcon{
  color: rgba(0, 0, 0, 0.87);
  opacity: .9;
  font-size: 12px;
  transform: translateY(-4px);
}

.closeIcon{
  color: rgba(0, 0, 0, 0.87);
}

div.dropdownMenu{
  position: absolute;
  width: 100% !important;
  border-top: unset !important;
  z-index: 1000;
  transform: translateY(6px);
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 224px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  width: calc(100% + 1px) !important;
  margin-left: -1px !important;
}

.dropdownMenu>:global(.item):first-child{
  border-radius: 0px !important;
}

a.preSelectedItem{
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.noOptions{
  padding: 0 16px;
}