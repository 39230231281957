.container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-radius: 5rem;
}

.container:global.medium {
  padding: 0.5rem;
  font-weight: 500;
}

.container:global.small {
  padding: 0.1rem 0.5rem;
  font-weight: 500;
}

.badge {
  margin-left: 0.3rem;
}
