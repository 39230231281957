.main {
  position: absolute;
  top: 10%;
  left: 50%;
  margin: 0 0 0 -300px !important;
  width: 600px;
  height: 100%;
}

.tos {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 600px;
  height: 50%;
  overflow-y: auto;
}

.content {
  margin: auto;
}

.checkbox {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.buttonSection {
  display: flex;
  justify-content: space-between;
}
