.boxContentGray {
  background-color: #f7f7f7;
  padding: 16px;
  text-align: center;
  line-height: 38px;
  border-radius: 6px;
  height: 70px;
  font-size: 32px;
}

.boxContentRed {
  background-color: rgb(248, 74, 74);
  color: #fff5f5;
  padding: 16px;
  text-align: center;
  line-height: 38px;
  border-radius: 6px;
  height: 70px;
  font-size: 32px;
}

.boxContentGreen {
  background-color: rgba(1, 164, 76, 0.2);
  color: #01a44c;
  text-align: center;
  padding: 16px;
  border-radius: 6px;
  height: 70px;
  font-size: 32px;
}

.boxContentValue {
  padding: 0 5px;
  font-weight: bold;
  line-height: 38px;
  display: inline-block;
  vertical-align: text-top;
}

.boxContentField {
  padding: 0 5px;
  font-weight: 400;
  line-height: 38px;
  display: inline-block;
  font-size: 18px;
  vertical-align: text-top;
}

.floatRight {
  float: right;
  height: 16px;
  width: 16px;
}

.goalSubHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pointer {
  cursor: pointer;
}
