.controls {
  margin-bottom: 5px;
}

.editContainer {
  padding-bottom: 100px !important;
}

.new {
  margin: 10px 0;
}

.body {
  color: var(--text-color-dark);
  font-size: 1.2em;
  line-height: 1.2em;
  padding: 20px;
}

.body img {
  max-width: 100%;
}

.calendarDropdown {
  min-width: 380px;
}

.tagFilters {
  margin-bottom: 10px;
}

.activeLabel {
  color: var(--brand-color) !important;
  background-color: var(--brand-color-light) !important;
}

.tagHeader {
  display: inline;
  margin-right: 0.5em !important;
}

.header {
  background: linear-gradient(white 56px, white 56px, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px 0 20px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.header h1 {
  color: var(--text-color-dark);
  font-weight: 300;
  margin: 0 0 0 10px;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header div {
  display: flex;
  align-items: center;
}
