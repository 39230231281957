.main {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10% 0 0 -250px !important;
}

.home {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 3em 1.4em !important;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.links {
  display: flex;
  justify-content: space-between;
}

.error {
  text-align: center;
}

.error a {
  color: #ffffff;
  text-decoration: underline;
}

.link {
  color: #61dafb;
  cursor: pointer;
}

.loginButton {
  width: 220px;
}

.hubspotButton {
  color: white !important;
  background-color: #ff5c35 !important;
}

.hubspotButton:hover {
  background-color: #d44325 !important;
}

.salesforceButton {
  color: white !important;
  background-color: #1b96ff !important;
}

.salesforceButton:hover {
  background-color: #0176d3 !important;
}
