.recommendationMarkdown {
  color: var(--text-color-dark);
  font-size: 1.2em;
  line-height: 1.5em;
  margin-top: 5px;
}

.recommendationMarkdown img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.recommendationMarkdown p {
  line-height: 1.5em;
}

.tags {
  margin-bottom: 25px;
}
