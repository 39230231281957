.line {
  color: var(--text-color) !important;
  padding: 0.7rem !important;
  text-align: left;
}

.menuItem {
  display: flex !important;
  justify-content: center !important;
  min-height: 6rem !important;
  padding: 0 !important;
}

.menuItem:hover {
  background-color: #E3E9FD !important;
}

.unseen {
  background-color: #FFFCB1 !important;
}

.unseen:hover {
  background-color: #F4F09A !important;
}

.alert {
  border-left: 0.3rem solid #E74939;
}

.leftItems {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.detail {
  margin-left: 2%;
  width: 75%;
}

.date {
  color: #1896C6;
  font-size: 0.8rem;
  padding: 3% 0 0 0;
}

.actions {
  align-items: center;
  color: #1896C6 !important;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  margin-right: 2%;
  min-height: 6rem;
  position: absolute;
  right: 0;
  top: 0;
}

.actionIcon {
  cursor: pointer !important;
}